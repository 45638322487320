import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Konva from 'konva';
import { render } from 'react-dom';
import { Stage, Layer, Group, Rect, Text, Image } from 'react-konva';
import useImage from 'use-image';

/**
 * @typedef {Object} TemplateLayer
 * @property {number} xOrigin - The x-coordinate of the layer
 * @property {number} yOrigin - The y-coordinate of the layer
 * @property {number} width - The width of the layer
 * @property {number} height - The height of the layer
 * @property {number} rotationAngle - The rotation angle of the layer in degrees
 * @property {number} zIndex - The z-index of the layer
 */

/**
 * @typedef {Object} TemplateImageLayerProps
 * @property {string} src - The source URL of the image
 * @property {"fit"|"cover"} resizeMode - The resize mode of the image
 * @typedef {TemplateLayer & TemplateImageLayerProps} TemplateImageLayer
 */
/**
 * An image layer in the template
 * @param {TemplateImageLayer} shapeProps - The properties of the image layer
 */
const TemplatePreviewImage = (shapeProps) => {
  const [image] = useImage(shapeProps.src, 'anonymous', 'origin');
  const [size, setSize] = useState({});

  const { resizeMode } = shapeProps;

  useEffect(() => {
    // Return if image isn't ready
    if (!image?.naturalHeight || !resizeMode) {
      return;
    }
    const widthRatio = shapeProps.width / image.naturalWidth;
    const heightRatio = shapeProps.height / image.naturalHeight;
    // Calculate ratio
    let r = 1;
    switch (resizeMode) {
      case 'fit':
        r = Math.min(widthRatio, heightRatio);
        break;
      case 'cover':
        r = Math.max(widthRatio, heightRatio);
        break;
      default:
        return;
    }
    setSize({ height: image.naturalHeight * r, width: image.naturalWidth * r });
  }, [image]);

  return (
    <Group
      x={shapeProps.xOrigin + shapeProps.width / 2}
      y={shapeProps.yOrigin + shapeProps.height / 2}
      rotation={shapeProps.rotationAngle}
      offsetX={shapeProps.width / 2}
      offsetY={shapeProps.height / 2}
      clipFunc={(ctx) => {
        ctx.rect(0, 0, shapeProps.width, shapeProps.height);
      }}
    >
      <Image
        image={image}
        x={size.width ? (shapeProps.width - size.width) / 2 : 0}
        y={
          resizeMode === 'fit'
            ? shapeProps.height - size.height
            : size.height
            ? (shapeProps.height - size.height) / 2
            : 0
        }
        width={size.width || shapeProps.width}
        height={size.height || shapeProps.height}
      />
    </Group>
  );
};

/**
 * @typedef {Object} TemplatePlaceholderLayerProps
 * @property {number} position - The order of the placeholder in the template
 * @typedef {TemplateLayer & TemplatePlaceholderLayerProps} TemplatePlaceholderLayer
 */
/**
 * A placeholder layer in the template
 * @param {TemplatePlaceholderLayer} shapeProps - The properties of the placeholder layer
 * @returns {React.Component}
 */
const TemplatePreviewPlaceholder = (shapeProps) => (
  <Rect
    {...shapeProps}
    x={shapeProps.xOrigin}
    y={shapeProps.yOrigin}
    rotation={shapeProps.rotationAngle}
    fill="#fff"
    strokeScaleEnabled={false}
    strokeWidth={1}
    stroke="#b7b7b7"
  />
);

/**
 * @typedef {Object} TemplateTextLayerProps
 * @property {string} text - The text content of the layer
 * @property {string} font - The font family of the text
 * @property {number} size - The font size of the text
 * @property {number[]} color - The color of the text in RGBA format
 * @property {number[]} transform - The transformation matrix of the text
 * @typedef {TemplateLayer & TemplateTextLayerProps} TemplateTextLayer
 */
/**
 * A text layer in the template
 * @param {TemplateTextLayer} shapeProps - The properties of the text layer
 * @returns {React.Component}
 */
const TemplatePreviewText = (shapeProps) => {
  const shapeRef = React.useRef(null);
  React.useEffect(() => {
    const shapeNode = shapeRef.current;
    if (!shapeNode) {
      return;
    }

    const { xOrigin, yOrigin, transform } = shapeProps;

    // Ignore deprecated transformation matrix if it is not set.
    if (_.isNil(transform) || transform.length === 0) {
      return;
    }

    const tf = shapeNode.getTransform();
    tf.m = transform;
    tf.translate(xOrigin, yOrigin);
  });
  return (
    <Text
      {...shapeProps}
      x={shapeProps.xOrigin}
      y={shapeProps.yOrigin}
      ref={shapeRef}
      fill={`rgba(${shapeProps.color.join(',')})`}
      fontFamily={`"${shapeProps.font}"`}
      fontSize={shapeProps.size}
      rotation={shapeProps.rotationAngle}
    />
  );
};

/**
 * @typedef {Object} Template
 * @property {string} id - The unique identifier of the template
 * @property {number} width - The width of the template
 * @property {number} height - The height of the template
 * @property {TemplatePlaceholderLayer[]} placeholders - The placeholders in the template
 * @property {TemplateImageLayer[]} images - The images in the template
 * @property {TemplateTextLayer[]} texts - The text layers in the template
 */

/**
 * A preview of a photo template design
 * @param {object} props - The component props
 * @param {number} props.stageWidth - The width to render the template
 * @param {number} props.stageHeight - The height to render the template
 * @param {Template} props.config - The configuration of the template
 * @param {Function} props.onClick - The function to call when the template is clicked
 * @param {Function} props.onBackgroundClick - The function to call when the background is clicked
 */
const TemplatePreview = ({ stageWidth, stageHeight, config, onClick, onBackgroundClick }) => {
  const stage = useRef();

  if (!config || !config.placeholders) {
    return <div data-testid={'TemplatePreview-Test'} />;
  }

  const templateScale = {
    x: config.width > config.height ? stageWidth / config.width : stageHeight / config.height,
    y: config.width > config.height ? stageWidth / config.width : stageHeight / config.height,
  };

  /**
   * @type {Array<TemplateImageLayer|TemplateTextLayer|TemplatePlaceholderLayer>}
   */
  const shapes = Object.entries(config)
    .reduce((accumulator, [key, value]) => {
      if (!['placeholders', 'images', 'texts'].includes(key)) {
        return accumulator;
      }
      accumulator.push(...value.map((shape) => ({ ...shape, category: key })));
      return accumulator;
    }, [])
    .sort((a, b) => (a.zIndex > b.zIndex ? 1 : -1));

  /**
   * Handle the template itself being clicked (not including the empty background)
   * @param {object} konvaEvent - Specialized event type from konva
   */
  const handleTemplateClick = (konvaEvent) => {
    konvaEvent.cancelBubble = true; // Cancel bubbling to the parent
    if (_.isFunction(onClick)) {
      onClick(konvaEvent);
    }
  };

  /**
   * Set cursor to pointer when the template is hovered
   * @returns {void} - Return early if no onClick prop
   */
  const handleTemplateMouseEnter = () => {
    if (!_.isFunction(onClick)) {
      return;
    }
    stage.current.container().style.cursor = 'pointer';
  };

  /**
   * Set cursor to default when template is no longer hovered
   * @returns {void} - Return early if no onClick prop
   */
  const handleTemplateMouseLeave = () => {
    if (!_.isFunction(onClick)) {
      return;
    }
    stage.current.container().style.cursor = 'default';
  };

  return (
    <div data-testid={`TemplatePreview-${config.id}`}>
      <Stage ref={stage} width={stageWidth} height={stageHeight} scale={templateScale} onClick={onBackgroundClick}>
        <Layer
          x={stageWidth / templateScale.x / 2 - config.width / 2}
          y={stageHeight / templateScale.y / 2 - config.height / 2}
        >
          <Group
            scale={{ x: 0.9, y: 0.9 }}
            x={config.width / 2 - (config.width * 0.9) / 2}
            y={config.height / 2 - (config.height * 0.9) / 2}
            onClick={handleTemplateClick}
            onMouseEnter={handleTemplateMouseEnter}
            onMouseLeave={handleTemplateMouseLeave}
          >
            <Rect
              x={0}
              y={0}
              strokeWidth={2}
              strokeScaleEnabled={false}
              stroke="rgba(0,0,0,0)"
              fill="white"
              width={config.width}
              height={config.height}
              shadowColor="#000"
              shadowBlur={50}
              shadowOffset={{ x: 0, y: 25 }}
              shadowOpacity={0.1}
            />
            <Group
              clip={{
                x: 0,
                y: 0,
                width: config.width,
                height: config.height,
              }}
            >
              <Rect
                fill="#e2e2e2"
                x={0}
                y={0}
                width={config.width}
                height={config.height}
                strokeScaleEnabled={false}
                strokeWidth={1}
                stroke="#b7b7b7"
              />
              {shapes.map((shape, i) => {
                switch (shape.category) {
                  case 'images':
                    return <TemplatePreviewImage key={i} {...shape} />;
                  case 'texts':
                    return <TemplatePreviewText key={i} {...shape} />;
                  case 'placeholders':
                    return <TemplatePreviewPlaceholder key={i} {...shape} />;
                  default:
                    return null;
                }
              })}
            </Group>
          </Group>
        </Layer>
      </Stage>
    </div>
  );
};

export default TemplatePreview;
